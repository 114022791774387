<template>
  <b-card no-body class="" style="padding: 30px">
    <div>
      <!-- Header: Personal Info -->
      <div class="d-flex pb-1">
        <feather-icon icon="UserIcon" size="19" />
        <h4 class="mb-0 ml-50">{{ $t("Personal Information") }}</h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-form class="mt-1" @submit.prevent="saveChanges">
        <b-row>
          <!-- FirstName -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="$t('First name')" label-for="first_name">
              <b-form-input v-model="first_name" id="first_name" />
            </b-form-group>
          </b-col>

          <!-- LastName -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="$t('Last name')" label-for="last_name">
              <b-form-input v-model="last_name" id="last_name" />
            </b-form-group>
          </b-col>

          <!-- Email -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="$t('Email')" label-for="email">
              <b-form-input v-model="email" id="email" required />
            </b-form-group>
          </b-col>

          <!-- Password -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="$t('Password')" label-for="password">
              <b-form-input type="password" v-model="password" id="password" />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Header: Personal Info -->
        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              {{ $t("Save Change") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-card>
</template>

<script>
import instance from "@/libs/axios";
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BCard,
    ToastificationContent,
  },
  async created() {
    try {
      const {
        data: { first_name, last_name, email },
      } = await instance.get("/users/profile/");

      this.first_name = first_name;
      this.last_name = last_name;
      this.email = email;
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },
  methods: {
    async saveChanges(event) {
      try {
        await instance.post("/users/profile/", {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          password: this.password,
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("It has been updated successfully"),
            icon: "CheckIcon",
            variant: "success",
          },
        });

        const userData = JSON.parse(localStorage.getItem("userData"));
        localStorage.setItem(
          "userData",
          JSON.stringify({
            ...userData,
            fullName: `${this.first_name} ${this.last_name}`,
            email: this.email,
          })
        );
        setTimeout(() => {
          this.$router.go(this.$router.currentRoute);
        }, 600);
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
  data() {
    return {
      first_name: null,
      last_name: null,
      email: null,
      password: null,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
